.eventlist_teaser {
  .tile-news-overview {
    background: $white;
    display: flex;
    gap: $grid-gutter-width;
    align-content: center;
    align-items: center;
  }
}

.event-teaser-inner {
  padding: 10px 20px;
}

.event {
  .btn {
    align-items: center;
    display: flex;
    padding: 8px;
    border-radius: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &-teaser-img {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}