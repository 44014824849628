.pricing {
  .card {
    &-body {
      padding-top: 26px;
      font-size: 16px;

      .subheadline {
        font-size: 18px;
        letter-spacing: 0;
      }

      li {
        position: relative;
        padding-left: 20px;
      }

      svg {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        fill: $secondary;
        top: 11px;
      }
    }

    &-footer {
      background-color: #fff;
    }

    .tag {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px;
      font-size: 16px;
      background: $secondary;
      color: #fff;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 5px;
      line-height: 1;
      font-weight: 300;

    }
  }
}