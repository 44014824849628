.ce_rsce_stoerer {
  display: inline-block;
  padding: 20px;
  margin-bottom: 35px;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .content + .btn {
    margin-top: 20px;
  }
}

.teaser-inline {
  display: inline-flex;
}

.teaser-full {
  display: block;
  width: 100%;
}