.blog_full_teaser {
  margin-bottom: 40px;
}

.blog-padding {
  padding: 20px;

  @include media-breakpoint-up(lg) {
    padding: 0 30px 60px
  }
}

.blog-teaser-inner {
  padding: 10px;
  width: 100%;
}

.layout_latest {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.info {
  display: block;

  svg {
    width: 16px;
    margin-right: 8px;
  }
}

.has-sidebar {
  #left,
  #right {
    margin-top: 40px;

    .mod_article {
      background-color: $white;
      padding: 20px;

      .input-group {
        background-color: $body-bg;
      }

      .form-control {
        padding: 0 20px;
        color: $body-color;
      }

      .btn:not(.reset) {
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }
  }

  #main,
  #left,
  #right {
    margin: 0 -20px 20px;

    @include media-breakpoint-up(md) {
      margin: 40px 0;
    }
  }
}

.mod_newslist_overview {
  .tile-news-overview {
    background-color: $white;
    overflow: hidden;

    figure {
      display: block;
      overflow: hidden;
      margin: 0;
    }
  }
}

.tile-news-overview {
  h4 {
    transition: color 150ms ease-in-out;
  }
}

@include media-breakpoint-down(sm) {
  .g-blog {
    --bs-gutter-y: 20px !important;
  }
}

@include media-breakpoint-down(md) {
  .newslist-start {
    .icon svg {
      height: 16px;
    }

    .h5 {
      font-size: 16px;
    }

    .btn-arrow {
      padding-top: 2px;
    }

    .image_container {
      margin: 0 !important;
      height: 100%;

      img {
        width: 95px;
        height: 100%;
        min-height: 95px;
        object-fit: cover;
      }
    }

    .date, .location {
      margin-bottom: 3px;
    }

    .tag {
      bottom: 0;
      font-size: 13px !important;
      left: 0 !important;
      position: absolute !important;
      right: unset;
      padding: 4px 8px !important;
      top: unset !important;
      border-top-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      line-height: 1;
      height: 20px;
    }

    .tile-news-overview {
      display: flex;
      padding: 0;

      .btn {
        align-items: center;
        display: flex;
        padding: 8px;
        border-radius: 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .blog-teaser-img img {
        max-width: 80px;
      }

      .info {
        line-height: 1;
      }

      .blog-teaser-content {
        display: none;
      }
    }

    .content-link {
      display: none;
    }

    .stretched-link {
      display: block !important;
    }
  }
}

.layout_full {
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    padding-top: 30px;
  }

  .header-img {
    margin-bottom: 20px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.whiteheader {
  padding-top: 50px;

  @include media-breakpoint-up(lg) {
    padding-top: 76px;
  }

  header {
    color: $white;
    height: auto;
    box-shadow: $box-shadow-sm;

    .headline{
      color: $body-color;
    }

    @include media-breakpoint-up(lg) {
      color: $body-color;
      background: #fff;

      .img {
        display: none;
      }

      .img2 {
        display: block;
      }

      .level_1 {
        color: $body-color;

        a:hover {
          &:after {
            border-bottom-color: $body-color !important;
          }
        }
      }
    }
  }
}

.has-sidebar {
  #left,
  #right {
    margin-top: 40px;

    .mod_article {
      background-color: $white;
      padding: 20px;

      .input-group {
        background-color: $body-bg;
      }

      .form-control {
        padding: 0 20px;
        color: $body-color;
      }

      .btn:not(.reset) {
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }
  }

  #main,
  #left,
  #right {
    margin: 0 -20px 20px;

    @include media-breakpoint-up(md) {
      margin: 40px 0;
    }
  }
}
