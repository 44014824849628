.ce_rsce_references {
  padding-top: 22px;
  padding-bottom: 25px;

  @include media-breakpoint-up(md) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .references-logo-col {
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      position: relative;

      &::after,
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        background: #fff;
        z-index: 10;
      }

      &::after {
        right: 21px;
        top: 0;
        width: 1px;
      }

      &::before {
        left: 0;
        right: 0;
        height: 1px;
      }
    }
  }
}

.references-logo-link {
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  transition: filter 150ms ease-in-out;
  flex: 0 0 auto;
  width: 50%;

  @include media-breakpoint-up(sm) {
    padding: 30px 45px;
  }

  @include media-breakpoint-up(md) {
    width: 33.3333%;
    filter: saturate(0);
  }

  @include media-breakpoint-up(lg) {
    width: 16.6666666667%;
    border-right: 1px solid #E2E2E3;
    border-bottom: 1px solid #E2E2E3;
  }

  &:hover {
    filter: saturate(1);
  }

  img,
  picture {
    height: auto;
    max-height: 180px;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
p:last-child {
  margin-bottom: 0;
}
.ce_rsce_customer {
  .crosslink {
    background: $body-bg;
    padding: 12px;
    margin-top: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .ce_rsce_quote {
    margin-top: 20px;
  }

  .ce_rsce_quote,
  .ce_rsce_quote .content p {
    margin-bottom: 0;
  }
}

.btn-toolbar {
  background: #fff;
  padding: 40px 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .btn {
    margin-right: 10px;

    &.active {
      background: $primary;
    }
  }
}