.team {
  background: #fff;
}

.team-members {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  img {
    width: 40%;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}

.team-details {
  width: 100%;

  @include media-breakpoint-up(lg) {
    position: relative;
    height: 100px;
  }
}

.team-name {
  width: 100%;
  display: block;
  margin: 0 0 20px;
}

.team-title {
  color: $date;
  margin: 15px 0 5px;
  letter-spacing: 1px;
  font-size: 18px;
  line-height: 1;
}

@include media-breakpoint-up(lg) {
  .team-content-inner {
    background: #fff;
    width: 100%;
    max-height: 100px;
    overflow: hidden;
    transition: max-height 150ms;
    position: absolute;
    bottom: 0;

    .btn {
      max-height: 0;
      opacity: 0;
      transition: all 150ms;
    }
  }

  .team-member:hover {
    .team-content-inner {
      max-height: 200px;

      .btn {
        margin-bottom: 20px;
        max-height: 50px;
        opacity: 1;
      }
    }
  }
}