.textimagehero-wrapper {
  padding: 20px 0;

  @include media-breakpoint-up(md) {
    padding: 40px 0;
  }

  &.has-image {
    @include media-breakpoint-up(md) {
      padding: 40px 0;
    }
  }
}

.ce_rsce_textimagehero {
  background: rgb(30,102,32);
  background: linear-gradient(27deg, $primary 33%, $secondary 70%, $primary 100%);
  color: #fff;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .textimagehero-content {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.has-content {
      padding: 0 0 25px 0;

      @include media-breakpoint-up(md) {
        padding: 0 0 30px 0;;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 40px 0 0;
      }

      .headline{
        margin-bottom: 20px;
      }
    }
    .headline{
      margin-bottom: 0;
    }
  }
}