.btn-primary {
  border-color: $primary-light;
  background: linear-gradient(to right, $secondary 50%, $primary 50%);
  background-size: 200% 0.5%;
  background-position: right bottom;
  transition: background-position .5s ease-out, color 250ms;
  color: #fff;
  @include media-breakpoint-up(md){
    &:hover,
    &:focus {
      border-color: $secondary;
      background-position: left bottom;
    }
  }
}

.btn-outline-primary {
  background: linear-gradient(to right, $secondary 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position .5s ease-out, color 250ms;
  @include media-breakpoint-up(md){
    &:hover,
    &:focus,
    .tile-news-overview:hover &,
    .tile-news-overview:focus-within & {
      border-color: $secondary;
      background-position: left bottom;
      color: #fff;
    }
  }
}

.btn-arrow {
  padding: 0 35px 0 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjNzkzMjg4IiBkPSJNNDIyLjYgMjc4LjZMNDQ1LjMgMjU2bC0yMi42LTIyLjYtMTQ0LTE0NEwyNTYgNjYuNyAyMTAuOCAxMTJsMjIuNiAyMi42TDMyMi44IDIyNCAzMiAyMjQgMCAyMjRsMCA2NCAzMiAwIDI5MC43IDAtODkuNCA4OS40TDIxMC44IDQwMCAyNTYgNDQ1LjNsMjIuNi0yMi42IDE0NC0xNDR6Ii8+PC9zdmc+");
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-weight: bold;
  transition: background-position 150ms;
  margin-right: -10px;
  @include media-breakpoint-up(md){
    &:hover,
    &:focus{
      background-position: right center;
    }

    &.primary {
      color: $primary;
    }
  }
}

.stretched-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    content: "";
  }
}