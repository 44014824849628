.timeline {
  &-intro {
    text-align: center;
  }

  &-content {
    padding: 40px 0;
  }

  &-item {
    position: relative;
    padding: 20px 0 20px 30px;

    @include media-breakpoint-up(md) {
      padding: 0;
      margin-top: -100px;

      &:first-child {
        margin-top: 0;
      }

      .small-img & {
        margin-top: -47px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 7px;
      top: 0;
      height: 100%;
      border-left: 2px dashed $primary;

      @include media-breakpoint-up(md) {
        left: 50%
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 18px;
      height: 18px;
      background: $primary;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        left: 50%;
        margin-left: -8px;
      }
    }

    @include media-breakpoint-up(md) {
      &.odd {
        text-align: right;
      }

      .timeline-details {
        width: 50%;
      }

      &.even {
        .timeline-details {
          margin-left: 50%;
        }
      }
    }
  }

  &-details {
    line-height: 1;

    @include media-breakpoint-up(md) {
      padding: 0 50px;
    }
  }

  &-date {
    margin-bottom: 6px;
    display: block;
  }

  &-picture {
    img {
      width: 170px;
      height: 170px;
      object-fit: cover;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        width: 270px;
        height: 270px;
      }
    }
  }

  &.small-img {
    img {
      width: 100px;
      height: 100px;
    }

    .timeline-item {
      padding-bottom: 20px;
    }
  }

  &-name p {
    font-weight: bold;
    @extend .h4;
  }

  .no-img {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: -30px;
    }

    .timeline-name p {
      line-height: 1.4;
      font-weight: normal;
    }
  }

  & + .custom-text {
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      padding-top: 64px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 120px;
    }
  }
}