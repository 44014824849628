.ce_form {
  background: #fff;
  color: $body-color;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
    position: relative;
    z-index: 10;
  }

  .btn{
    margin-top: 15px;
  }
}

.form-control {
  border: none;
  border-bottom: 1px solid $body-color;
  color: $body-color;
}

.form-check-input {
  border-color: $body-color;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  left: -3px;
}