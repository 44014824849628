.ce_rsce_text {
  .content {
    column-gap: 40px;

    @include media-breakpoint-up(xl) {
      &.column-2 {
        column-count: 2;
      }

      &.column-3 {
        column-count: 3;
      }
    }
  }
}

.icon-col {
  text-align: left;

  @include media-breakpoint-up(md) {
    width: 60px;
    text-align: center;
  }

  img {
    max-height: 50px;
    max-width: 50px;
    width: auto;
    height: auto;
  }
}

.table-zug {
  tr {
    position: relative;
  }

  td {
    align-content: center;
    vertical-align: middle;
  }

  a {
    text-decoration: none;

    @include media-breakpoint-up(md) {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjZTQ2ZDIxIiBkPSJNNDIyLjYgMjc4LjZMNDQ1LjMgMjU2bC0yMi42LTIyLjYtMTQ0LTE0NEwyNTYgNjYuNyAyMTAuOCAxMTJsMjIuNiAyMi42TDMyMi44IDIyNCAzMiAyMjQgMCAyMjRsMCA2NCAzMiAwIDI5MC43IDAtODkuNCA4OS40TDIxMC44IDQwMCAyNTYgNDQ1LjNsMjIuNi0yMi42IDE0NC0xNDR6Ii8+PC9zdmc+");
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 20px;
        transition: background-position 150ms;
      }

      &:hover,
      &:focus {
        &::after {
          background-position: right 10px center;
        }
      }
    }
  }

  &.ewigetabelle {
    th {
      font-size: 16px;
    }

    .odd td {
      background: #fff;
    }

    .bg-success td {
      background: lighten($primary, 60%);
    }
  }

  .icon-col {
    @include media-breakpoint-up(md) {
      width: 40px;
    }

    img {
      max-height: 30px;
      max-width: 30px;
    }
  }
}

.ewigetabelle {
  background: #fff;

  td.sorting_1 {
    background: lighten($primary, 50%) !important;
  }
}
