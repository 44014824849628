@import "webfonts";
@import "variables";
@import "variables-dark";

@import "../../node_modules/bootstrap/scss/mixins/banner";

@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
//@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
//@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "typo";
@import "buttons";

@import "layout/header";
@import "layout/navigation";
@import "layout/sidebar";
@import "layout/footer";

@import "widgets/3images";
@import "widgets/3teaser";
@import "widgets/blog";
@import "widgets/contact";
//@import "widgets/divider";
//@import "widgets/features";
@import "widgets/hero";
@import "widgets/herosub";
@import "widgets/image";
@import "widgets/imgsplit";
//@import "widgets/knowhow";
//@import "widgets/list";
//@import "widgets/listimage";
//@import "widgets/logoteaser";
//@import "widgets/newsletter";
@import "widgets/newsteaser";
@import "widgets/pricecards";
@import "widgets/quote";
//@import "widgets/search";
@import "widgets/stoerer";
@import "widgets/specialevents";
@import "widgets/tagcloud";
@import "widgets/team";
@import "widgets/text";
@import "widgets/textasym";
@import "widgets/textimage";
@import "widgets/textimagehero";
@import "widgets/timeline";
@import "widgets/references";
@import "widgets/zahlen";


//
@import "modules/events";
@import "modules/forms";
@import "modules/tables";

.main-container{
  overflow-x: hidden;
}

.mt-row {
  margin-top: 22px;

  @include media-breakpoint-up(md) {
    margin-top: 64px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 120px;
  }
}

.mb-row {
  margin-bottom: 22px;

  @include media-breakpoint-up(md) {
    margin-bottom: 64px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }
}

.pt-row {
  padding-top: 22px !important;

  @include media-breakpoint-up(md) {
    padding-top: 64px !important;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 120px !important;
  }
}

.pb-row {
  padding-bottom: 22px;

  @include media-breakpoint-up(md) {
    padding-bottom: 64px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }
}

.py-row {
  padding-bottom: 22px;
  padding-top: 22px;

  @include media-breakpoint-up(md) {
    padding-bottom: 64px;
    padding-top: 64px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
    padding-top: 120px;
  }

  &:not(.bg-white) + .py-row:not(.bg-white),
  &.bg-white + .py-row.bg-white {
    padding-top: 0;
  }
}

.w-800.w-800 {
  max-width: 800px!important;
}

.skiplinks {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 1px;
  width: 1px;
  z-index: 10000;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  .skip:focus,
  .skip:active {
    left: 9999px;
    outline: 0 none;
    width: 100%;
    height: auto;
    color: #fff;
    background: $primary;
    opacity: .75;
    padding: 3px 0;
    text-align: center;
    text-decoration: none;
  }
}

.skip {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 1px;
  width: 1px;
}

.bg-white {
  background: #fff;
}

.container-xl {
  max-width: 1140px !important;

  .container-xl {
    padding: 0;
  }
}

.todo {
  color: deeppink !important;
}

#container {
  min-height: calc(100vh - 135px - 126px);
}

.focus-inside {
  &:focus-within {
    box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
    outline: 0;
  }
}

.table {
  width: 100% !important;
}