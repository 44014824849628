.nav-pills {
  display: flex;
  margin: -10px -20px 0;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 20px 5px;
  white-space: nowrap;
  flex-wrap: nowrap;

  .nav-item {
    display: inline-block;
    list-style: none;
    padding-bottom: 12px;
    position: relative;
  }

  .btn {
    margin-right: 10px;
    position: relative;

    &-primary {
      padding-left: 20px;
      padding-right: 40px;

      svg {
        fill: #fff;
        width: 18px;
        height: 18px;
        position: absolute;
        right: 7px;
        top: 9px;
      }
    }
  }
}
