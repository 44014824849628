.table {
  thead {
    display: none;

    @include media-breakpoint-up(md) {
      display: table-header-group;
    }
  }

  tr {
    display: block;

    @include media-breakpoint-up(md) {
      display: table-row;
    }
  }

  td {
    display: block;

    @include media-breakpoint-up(md) {
      display: table-cell;
    }

    &::before {
      content: attr(data-label);
      float: left;
      width: 140px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}