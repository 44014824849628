@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/lora-latin-400-italic.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/lora-latin-400-normal.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/lora-latin-500-italic.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../fonts/lora-latin-500-normal.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/lora-latin-600-italic.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../fonts/lora-latin-600-normal.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/lora-latin-700-italic.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/lora-latin-700-normal.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/roboto-latin-400-normal.woff2') format('woff2'); /* Modern Browsers */
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/roboto-latin-400-italic.woff2') format('woff2'); /* Modern Browsers */
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/roboto-latin-700-normal.woff2') format('woff2'); /* Modern Browsers */
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url('../fonts/roboto-latin-700-italic.woff2') format('woff2'); /* Modern Browsers */
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../fonts/bebas-neue-latin-400-normal.woff2') format('woff2'); /* Modern Browsers */
}