.ce_rsce_textimage {
  &.flat {
    .textimage-img {
      img {
        width: 100%;
        height: auto;
      }
    }

    .col-md-8.col-xl-9.order-lg-1 {
      display: flex;
      align-items: center;
    }

    .img-float-right {
      .textimage-img {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          float: right;
          max-width: 50%;
          margin: 0 0 20px 20px;
        }
      }
    }

    .img-float-left {
      .textimage-img {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
          float: left;
          max-width: 50%;
          margin: 0 20px 20px 0;
        }
      }
    }
  }

  &.boxed {
    .text-image-container {
      .textimage-img {
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .text-image-text-container {
      @include media-breakpoint-up(lg) {
        margin-left: -64px;
        margin-top: 40px;
        padding: 80px;
        height: 100%;
        background-color: $white;
        box-shadow: 5px 5px 10px $gray-300;
      }

      &.img-right {
        margin-left: 0;
        margin-right: -64px;
        position: relative;
        z-index: 10;
        box-shadow: -5px 5px 10px $gray-300;
      }

      .btn {
        width: 100%;
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ce_rsce_textimageasynch {
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    overflow-x: visible;
  }

  .textimageasynch-img {
    @include media-breakpoint-up(sm) {
      position: relative;
      z-index: 1;
      margin-left: -70px;
      margin-right: -50%;
      transform: translate(20px, 25%);
    }

    @include media-breakpoint-up(lg) {
      margin-top: -120px;
      margin-left: -210px;
      margin-right: unset;
      transform: unset;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  .textimageasynch-content {
    position: relative;
    margin: 0 -20px;
    padding: 50px 20px;
    @include media-breakpoint-up(sm) {
      padding: 70px 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 120px 140px 120px 20px;
    }

    &-inner {
      z-index: 10;
      position: relative;
    }

    &:before {
      content: '';
      background: $header-bg;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      z-index: 1;
      width: calc(50vw + 20px);
      position: absolute;
    }
  }
}