.x-teaser {
  &-image-wrapper {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-item {
    @include media-breakpoint-up(md){
      &:hover {
        img {
          scale: 1.05;
        }
      }
    }

    picture {
      overflow: hidden;
    }

    img {
      scale: 1;
      transition: scale .3s ease;
    }

    .btn {
      margin-top: -30px;
      position: relative;
      z-index: 10;
    }
  }
}