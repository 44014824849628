header {
  background: #fff;
  transition: all 150ms;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 0;
  color: $body-color;

  @include media-breakpoint-up(xl) {
    background: transparent linear-gradient(180deg, #0B0E22 0%, #00000109 97%, #00000000 100%) 0 0 no-repeat padding-box;
    padding: 13px 0;
    height: 100px;
    color: #fff;
  }

  .logo {
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms;

    img {
      @include media-breakpoint-up(xl) {
        width: 230px;
        height: auto;
      }
    }

    &-col {
      min-height: 50px;
    }
  }

  .scrolled & {
    color: $white;
    height: auto;
    box-shadow: $box-shadow-sm;

    @include media-breakpoint-up(xl) {
      color: $body-color;
      background: #fff;
    }
  }
}

.navbar-toggler {
  padding: 15px;
  color: $body-color;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.navbar-toggler {
  padding: 0;
  width: 50px;
  height: 50px;
  border: none;
  box-shadow: none !important;
  outline: none;
  margin-right: -10px;
  color: $primary;

  .hamburger {
    display: block;
    width: 50px;
    height: 50px;

    position: relative;
    margin-left: auto;
    margin-right: auto;

    .line {
      position: absolute;
      left: 12.5px;
      height: 4px;
      width: 30px;
      background: currentColor;
      display: block;
      transition: 0.5s;
      transform-origin: center;
    }

    .line:nth-child(1) {
      top: 13px;
    }

    .line:nth-child(2) {
      top: 24px;
    }

    .line:nth-child(3) {
      top: 35px;
    }
  }

  &.open {
    .line:nth-child(1) {
      top: 12px;
      transform: translateY(12px) rotate(-45deg);
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(3) {
      top: 36px;
      transform: translateY(-12px) rotate(45deg);
    }
  }

  .close-menu {
    display: none;
  }
}

.invisible {
  height: 0;
  display: inherit;
}
