.ce_rsce_quote {
  display: inline-block;
  padding: 0;
  margin-bottom: 35px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.addSpacer {
    display: block;
    position: relative;

    &:before {
      content: '';
      background: url("../img/pattern.svg") repeat-x;
      position: relative;
      width: 100%;
      height: 20px;
      display: block;
      margin-bottom: 40px;
    }

    &:after {
      content: '';
      background: url("../img/pattern.svg") repeat-x;
      position: relative;
      width: 100%;
      height: 20px;
      display: block;
      margin-top: 40px;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}