.has-sidebar {
  @include media-breakpoint-up(lg) {
    display: grid;
  }

  &-left {
    @include media-breakpoint-up(lg) {
      grid-template-columns: 3fr 9fr;
      gap: 20px;
    }
  }

  &-right {
    @include media-breakpoint-up(lg) {
      grid-template-columns: 9fr 3fr;
      gap: 20px;
    }
  }
}