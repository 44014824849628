.ce_rsce_text_asym {
  position: relative;

  .color-container {
    &:before {
      content: "";
      background-color: $secondary;
      height: calc(50% - 50px);
      left: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;

      @include media-breakpoint-up(lg) {
        height: 100%;
        width: calc(50% - 50px)
      }
    }

    .box {
      z-index: 2;

      &-left {
        color: $white;
        position: relative;
        padding-bottom: 60px;

        @include media-breakpoint-up(lg) {
          padding-right: 100px;
          position: static;
          padding-bottom: unset;
        }

        &:after {
          content: '';
          position: absolute;
          background-color: $secondary;
          clip-path: polygon(100% 0,100% 19%,20% 100%,0 71%,0 0);
          height: 100px;
          display: block;
          //left: calc($grid-gutter-width*-.5);
          //right: calc($grid-gutter-width*-.5);
          left: -8px;
          right: -8px;
          bottom: -80px;

          @include media-breakpoint-up(lg) {
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            height: 100%;
            width: 50px;
            left:calc(50% - 50px);
            right: auto;
            top: 0;
            bottom: 0;
          }
        }
      }

      &-right {
        padding-top: 100px;
        @include media-breakpoint-up(lg){
          padding-top: unset;
          padding-left: 50px;
        }
      }
    }

    .headline {
      &-left {
        color: $white;
      }

      &-right {
        color: $secondary;
      }
    }

    .content {
      &-left {
        ul {
          li {
            &:before {
              background-color: $white;
            }
          }
        }
      }

      &-right {
      }
    }
  }
}