.footer {
  color: $white;
  background: rgb(85, 85, 85);
  background: linear-gradient(145deg, rgba(85, 85, 85, 1) 0%, rgba(34, 34, 34, 1) 100%);
  padding-top: 50px;
  padding-bottom: 50px;

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: block;

    @include media-breakpoint-up(md) {
      display: flex;
      gap: 20px;
    }


    li {
      padding: 0;
      position: relative;
      display: block;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
      }

      &:before {
        border-bottom: 2px solid transparent;
        bottom: -5px;
        content: "";
        left: 10%;
        position: absolute;
        right: 10%;
        transition: border-bottom-color .3s ease-in-out;
      }

      &.active {
        font-weight: bold;
      }

      @include media-breakpoint-up(md) {
        &:hover,
        &.active {
          font-weight: normal;

          &:before {
            border-bottom-color: $white;
          }
        }
      }
    }
  }
}
