.mod_navigation {
  @include media-breakpoint-up(xl) {
    display: flex;
    height: 100%;
  }

  &.navbar {
    padding: 0;
  }

  .offcanvas-backdrop {
    top: 50px;
  }

  .offcanvas.offcanvas-start {
    background: #eee;
    top: 50px;
    overflow-y: auto;

    @include media-breakpoint-up(xl) {
      overflow: visible;
    }
  }

  .level_1 {
    list-style: none;
    color: $black;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    height: 100%;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      padding: 5px 0;
      gap: 0;
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xxl) {
      gap: 30px;
    }

    .btn {
      white-space: nowrap
    }

    > li {
      white-space: nowrap;
      position: relative;
      line-height: 1;

      @include media-breakpoint-up(xl) {
        white-space: nowrap;
        position: relative;
        margin-bottom: 0;
        line-height: 30.4px;
      }

      @include media-breakpoint-up(xl) {
        &::before {
          content: '';
          position: absolute;
          bottom: 5px;
          left: 20%;
          right: 20%;
          border-bottom: 2px solid transparent;
          transition: border-bottom-color .3s ease-in-out;
        }

        &:hover,
        &.active {
          .scrolled &,
          .whiteheader & {
            color: $body-color;

            &::before {
              border-bottom-color: $body-color;
            }
          }

          &::before {
            border-bottom-color: $white;
          }
        }
      }

      a:not(.btn), span {
        color: $primary;
        text-decoration: none;
        transition: color 150ms ease-in-out;
        display: block;
        padding: 15px 20px;
        border-bottom: 2px solid #fff;
        font-weight: 700;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjY2Q2YThlIiBkPSJNNDIyLjYgMjc4LjZMNDQ1LjMgMjU2bC0yMi42LTIyLjYtMTQ0LTE0NEwyNTYgNjYuNyAyMTAuOCAxMTJsMjIuNiAyMi42TDMyMi44IDIyNCAzMiAyMjQgMCAyMjRsMCA2NCAzMiAwIDI5MC43IDAtODkuNCA4OS40TDIxMC44IDQwMCAyNTYgNDQ1LjNsMjIuNi0yMi42IDE0NC0xNDR6Ii8+PC9zdmc+");
        background-position: right 20px center;
        background-repeat: no-repeat;
        background-size: 20px;

        &.active {
          background: $primary;
          color: $white;

          @include media-breakpoint-up(xl) {
            background: transparent;
            color: $body-color;
          }
        }

        &.submenu {
          color: $body-color;
          background: none;
        }

        @include media-breakpoint-up(xl) {
          color: currentColor;
          padding: 10px 15px;
          background: none;
          border: none;
          font-weight: normal;

          .scrolled &,
          .whiteheader & {
            color: $body-color;
          }
        }
      }

      &.submenu {
        &::after {
          display: none;
        }

        @include media-breakpoint-up(xl) {
          .submenu {
            &::after {
              content: '';
              display: inline-block;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23000000'%3E%3Cpath d='M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z'/%3E%3C/svg%3E");
              width: 15px;
              height: 15px;
              position: relative;
              margin-left: 5px;
              transform: rotate(0deg);
              transition: transform .3s ease-in-out;
            }
          }
        }

        &:hover,
        &:focus-within {
          .submenu {
            &::after {
              transform: rotate(180deg);
            }

            & + .level_2 {
              display: block;
              z-index: 1;
              opacity: 1;
              max-height: 500px;

              @include media-breakpoint-up(xl) {
                margin-top: 0;
                box-shadow: 1px 10px 10px #0001;
              }
            }
          }
        }
      }
    }
  }

  .level_2 {
    display: block;
    background: transparent;
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      position: absolute;
      padding: 0 20px;
      max-height: 0;
      overflow: hidden;
      background: $white;
      opacity: 0;
      left: -4px;
      transition: all .3s;
      transition-property: opacity, margin-top, max-height;

      .active {
        color: $body-color;
      }

      li {
        text-align: center;

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
        }

        a, span {
          position: relative;
          color: $black;

          &::before {
            border-bottom: 2px solid transparent;
            bottom: 0;
            content: "";
            left: calc(20% + 14px);
            position: absolute;
            right: calc(20% + 14px);
            transition: border-bottom-color .3s ease-in-out;
          }
        }

        &:hover,
        &.active {
          a::before,
          span::before {
            border-bottom-color: $body-color;
          }
        }
      }
    }

    > li {
      position: relative;
      line-height: 1;

      a, strong, span {
        color: $primary;
        display: block;
        padding: 15px 40px;

        @include media-breakpoint-up(xl) {
          color: $body-color !important;
          padding: 10px 28px;
        }
      }
    }

    strong {
      font-weight: 500;
    }
  }
}

.transparent-header {
  @include media-breakpoint-up(xl) {
    .level_1 {
      color: $white;

      & > li.submenu a,
      & > li.submenu span {
        color: $white;
        background: none;
      }
    }

    &.scrolled {
      color: $body-color;

      .level_1 {
        color: $white;

        & > li.submenu a,
        & > li.submenu span {
          color: $body-color;
        }
      }

      .mod_navigation .level_1 > li.submenu .submenu::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23cd6a8e'%3E%3Cpath d='M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z'/%3E%3C/svg%3E");
      }
    }
  }

  .mod_navigation .level_1 > li.submenu .submenu::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%23ffffff'%3E%3Cpath d='M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z'/%3E%3C/svg%3E");
  }
}

.no-state {
  &:hover {
    &:before {
      content: unset!important;
    }
  }

  .btn-mobile {
    display: block;
    border-radius: 0;
    z-index: 1;
    position: relative;
    margin: 15px;
  }
}