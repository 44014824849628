.hero {
  .headline:not(h3) {
    font-size: $hero-font-size-mobile!important;
    line-height: 1;
    font-weight: 800;
    margin-bottom: 15px;
    color: $secondary;

    @include media-breakpoint-up(md) {
      font-size: $hero-font-size-tablet!important;
    }

    @include media-breakpoint-up(lg) {
      font-size: $hero-font-size!important;
      margin-bottom: 30px;
    }
  }

  .subheadline {
    @include media-breakpoint-up(lg) {
      font-size: 24px;
      font-style: italic;
    }
  }
}

.herosub {
  &.bg-primary{
    .py-row{
      @include media-breakpoint-up(md){
        padding-top: 32px;
        padding-bottom: 32px;
      }

      @include media-breakpoint-up(lg){
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }
  .headline:not(h2), .headline:not(h3) {
    @include media-breakpoint-up(lg) {
      font-size: $hero-font-size;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1;
    }
  }
  &h2, &.h2 {
    font-size: $h2-font-size-mobile!important;

    @include media-breakpoint-up(md) {
      font-size: $h2-font-size-tablet!important;
    }

    @include media-breakpoint-up(lg) {
      font-size: $h2-font-size!important;
    }
  }
  &h3, &.h3 {
    font-size: $h3-font-size-mobile!important;

    @include media-breakpoint-up(md) {
      font-size: $h3-font-size-tablet!important;
    }

    @include media-breakpoint-up(lg) {
      font-size: $h3-font-size!important;
    }
  }

  .subheadline {
    @include media-breakpoint-up(lg) {
      font-size: 24px;
      font-style: italic;
    }
  }
}

h1, .h1 {
  font-size: $h1-font-size-mobile;
  margin-bottom: 15px;
  color: $secondary;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size-tablet;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
  }
}

h2, .h2 {
  font-size: $h2-font-size-mobile;
  margin-bottom: 15px;
  color: $secondary;

  @include media-breakpoint-up(md) {
    font-size: $h2-font-size-tablet;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size;
  }
}

h3, .h3 {
  font-size: $h3-font-size-mobile;
  margin-bottom: 15px;
  color: $secondary;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size-tablet;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size;
  }
}

h4, .h4 {
  font-size: $h4-font-size-mobile;
  margin-bottom: 15px;
  color: $secondary;

  @include media-breakpoint-up(md) {
    font-size: $h4-font-size-tablet;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size;
  }
}

h5, h6, .h5, .h6 {
  font-size: $h5-font-size-mobile;
  margin-bottom: 15px;
  color: $secondary;

  @include media-breakpoint-up(md) {
    font-size: $h5-font-size-tablet;
  }

  @include media-breakpoint-up(lg) {
    font-size: $h5-font-size;
  }
}


p, ul {
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6,
  & + .h1,
  & + .h2,
  & + .h3,
  & + .h4,
  & + .h5,
  & + .h6 {
    margin-top: 30px;
  }
}

.subheadline {
  letter-spacing: 2px;
  font-weight: 600;
  font-family: $subheading-font-family;
  font-style: italic;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  &#{&}--light {
    color: $secondary
  }

  &#{&}--bot-10 {
    margin-bottom: 10px;
  }
}

.headline {
  font-family: $headings-font-family;
}

.nav-link,
.link {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: $navbar-nav-link-padding-x;
    top: 0;
    height: 2px;
    background-color: $secondary;
    width: 0;
    transition: width .5s ease-in;
  }

  &:hover,
  &.active {
    &:after {
      width: 30px;
    }
  }
}

p a:not(.btn) {
  position: relative;
  transition: color .3s ease;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    height: 2px;
    background-color: $primary;
    width: 100%;
    right: 0;
    transition: right .5s ease-in;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    height: 2px;
    background-color: $secondary;
    width: 100%;
    right: 100%;
    transition: right .5s ease-in;
    z-index: 2;
  }

  &:hover,
  &.active {
    color: $secondary;

    &:after {
      right: 0;
    }
  }
}

.content {
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-left: 20px;
      position: relative;
      padding-bottom: 0.25rem!important;
      padding-top: 0.25rem!important;
      list-style: none;

      &:before {
        width: 10px;
        height: 10px;
        position: absolute;
        left: 0;
        top: 13px;
        content: '';
        background-color: $secondary;
        display: inline-block;
        mask: $list-bullet;
        mask-repeat: no-repeat;

      }
    }
  }
}