.herosub{
  .background-wrapper{
    position: absolute;
    height: 100%;
    display: flex;

    img{
      height: 100%;
      opacity: .3;
      width: auto;
      max-height: 40vh;
    }
    &.bg-ctr{
      left: 0;
      right: 0;
      top: 50%;
      transform:translateY(-50%);
      align-items: center;
    }

    &.bg-l-down{
      left: 0;
      bottom: 0;
      text-align: left;
      align-items: flex-end;
    }

    &.bg-l-up{
      left: 0;
      top: 0;
      text-align: left;
      align-items: flex-start;
    }

    &.bg-r-down{
      right: 0;
      bottom: 0;
      text-align: right;
      align-items: flex-end;
    }

    &.bg-r-up{
      right: 0;
      top: 0;
      text-align: right;
      align-items: flex-start;
    }
  }
}