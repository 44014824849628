.hero {
  margin: 0 auto;

  .headline-wrapper {
    @include media-breakpoint-up(lg) {
      max-width: 60%;
    }
  }

  &.stoerer-img {
    height: 50vh;

    img {
      width: 100%;
      height: 50vh;
      max-height: 100%;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 80vh;
    max-height: 400px;
    object-fit: cover;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      height: calc(100vh - 76px);
    }
  }

  &.hero-small img {
    height: 50vh !important;
  }

  .subheadline {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 28px;
      margin-bottom: 5px;

    }
  }

  .headline {
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }

  &:not(.stoerer-img) {
    .background-wrapper {
      position: relative;
      max-width: 100%;
      overflow: hidden;

      &:before {
        z-index: 1;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.9668242296918768) 0%, rgba(255, 255, 255, 0.8631827731092436) 41%, rgba(255, 255, 255, 0) 61%);
        opacity: 0.8;
      }
    }
  }

  &-wrapper {
    bottom: 0;
    left: 0;
    z-index: 10;
    right: 0;
    top: 0;
    color: $secondary;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }
}

.circle-wrapper {
  position: relative;
  margin-bottom: 100px;

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  &-big {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 30px;
      bottom: -60px;
      height: 460px;
      width: 460px;
      z-index: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        border-radius: 50%;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        content: '';
        background-color: lighten($secondary, 10);
        height: 470px;
        left: 0;
        position: absolute;
        top: 0;
        width: 470px;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }

  &-small {
    height: 150px;
    left: 50%;
    position: absolute;
    right: 50%;
    width: 150px;
    bottom: -50px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(lg) {
      position: absolute;
      width: 200px;
      height: 200px;
      left: auto;
      transform: none;
      right: 30px;
      bottom: -130px;
    }

    figure {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain
    }
  }
}

.shorten-text {
  @include media-breakpoint-up(lg) {
    padding-right: 50vw;
  }
  @include media-breakpoint-up(xl) {

  }
  @include media-breakpoint-up(xxl) {
    padding-right: 25vw;
  }
}
